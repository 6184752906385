import { Box, Button } from "@mui/material";
import { Stack } from "@mui/system";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import MaterialReactTable from "material-react-table";
import moment from "moment";
import React, { useCallback, useEffect, useReducer, useState } from "react";

import { getAuditLogsData } from "api";

import {
  COLORS,
  DatePickerField,
  Loading,
  MarginWrapper,
  usePagination,
  useTableExpandedRows,
  useYearMonthPeriodFilter,
  useYearPeriodFilter,
} from "components";

import { useNotify } from "hooks";

import { LOCAL_STORAGE_KEYS } from "consts";

import { ParsedQueryParams } from "types";

import { useNewBreadcrumb } from "recoils";

import { getDateFormatset } from "utils";

import { AUDIT_LOGS_COLUMNS_MAIN } from "./components/auditLogs-table/columnsMain";
import { AUDIT_LOGS_PAGE } from "./consts";

const firstDay = new Date();
const lastDay = new Date();

const initialState = {
  isYearly: false,
  startDate: moment(firstDay).format("YYYY-MM-DD"),
  endDate: moment(lastDay).format("YYYY-MM-DD"),
  fromDate: null,
  toDate: null,
  view: "monthly",
};

function reducer(state: any, action: any) {
  let isYearly;
  let period;

  switch (action.type) {
    case "SET_YEARLY":
      ({ isYearly, period } = action.payload);
      return {
        ...state,
        isYearly,
        view: isYearly ? "yearly" : "monthly",
        startDate: isYearly
          ? period?.startOf("year").format("YYYY-MM-DD")
          : `${period.format("YYYY-MM")}-01`,
        endDate: isYearly
          ? period?.endOf("year").format("YYYY-MM-DD")
          : `${period.format("YYYY-MM")}-${period?.daysInMonth()}`,
      };
    case "SET_FROM_DATE":
      return {
        ...state,
        fromDate: action.payload.value,
        view: "daterange",
        startDate: action.payload.value,
      };
    case "SET_TO_DATE":
      return {
        ...state,
        toDate: action.payload.value,
        view: "daterange",
        endDate: action.payload.value,
      };
    case "SET_START_AND_END_DATES":
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };

    case "CLEAR_DATES":
      return {
        ...state,
        fromDate: null,
        toDate: null,
        startDate: moment(firstDay).format("YYYY-MM-DD"),
        endDate: moment(lastDay).format("YYYY-MM-DD"),
        isYearly: false,
        view: "monthly",
      };
    default:
      return state;
  }
}

export const AuditLogsPage = () => {
  // ---- States ----
  const [state, dispatch] = useReducer(reducer, initialState);
  const [clearDate, setClearDate] = useState(false);
  const { isYearly, startDate, endDate, fromDate } = state;
  const [dateError, setDateError] = useState(false);

  // ---- Hook ----
  const notify = useNotify();

  useNewBreadcrumb(AUDIT_LOGS_PAGE.TITLE);
  const { setInitialExpanded, updateQueryParamsWithInitialExpanded } =
    useTableExpandedRows();

  const { setInitialPage, updateQueryParamsWithInitialPage } = usePagination({
    pageSizeLocalStorageKey: LOCAL_STORAGE_KEYS.EMISSION_SUMMARY_SIZE,
    updateExternalStates: setInitialExpanded,
    getQueryParamsWithExternalChanges: updateQueryParamsWithInitialExpanded,
  });
  const updateExternalStates = useCallback(() => {
    setInitialPage();
    setInitialExpanded();
  }, [setInitialPage, setInitialExpanded]);

  const getQueryParamsWithExternalChanges = useCallback(
    (queryParams: ParsedQueryParams) =>
      updateQueryParamsWithInitialExpanded(
        updateQueryParamsWithInitialPage(queryParams),
      ),
    [updateQueryParamsWithInitialExpanded, updateQueryParamsWithInitialPage],
  );
  const { period, availableYears } = useYearMonthPeriodFilter({
    updateExternalStates,
    getQueryParamsWithExternalChanges,
    isYearly,
  });

  const { year } = useYearPeriodFilter({
    updateExternalStates: undefined,
    withQueryParams: undefined,
    useYears: undefined,
    initialValue: dayjs().utc().year(),
  });

  const minDate = dayjs(
    `${Math.min(...(availableYears || [dayjs().utc().year()]))}-01-01`,
  ).utc();
  const maxDate = dayjs().utc();

  const handleFromDateChange = (date: any) => {
    const firstDate = new Date(date);
    const fromDateValue = moment(firstDate).format("YYYY-MM-DD");
    dispatch({ type: "SET_FROM_DATE", payload: { value: fromDateValue } });
  };

  const handleToDateChange = (date: any) => {
    const toDate = new Date(date);
    const toDateValue = moment(toDate).format("YYYY-MM-DD");
    dispatch({ type: "SET_TO_DATE", payload: { value: toDateValue } });
    if (fromDate && toDateValue < fromDate) {
      setDateError(true);
    } else {
      dispatch({
        type: "SET_TO_DATE",
        payload: { value: toDateValue, period },
      });
      setDateError(false);
    }
  };

  const handleClearDates = () => {
    dispatch({ type: "CLEAR_DATES", payload: null });
    setDateError(false);
    setClearDate(true);
    setTimeout(() => {
      setClearDate(false);
    }, 100);
    window.location.reload();
  };

  useEffect(() => {
    dispatch({
      type: "SET_FROM_DATE",
      payload: { value: period.format("YYYY-MM-DD") },
    });
    dispatch({
      type: "SET_TO_DATE",
      payload: { value: period.format("YYYY-MM-DD") },
    });
    dispatch({
      type: "SET_START_AND_END_DATES",
      payload: {
        startDate: period.format("YYYY-MM-DD"),
        endDate: period.format("YYYY-MM-DD"),
      },
    });
    // eslint-disable-next-line
  }, [period, year]);

  const {
    data: auditLogs,
    isLoading,
    mutate: auditLogsMutation,
  } = useMutation(getAuditLogsData);

  useEffect(() => {
    const date1: any = new Date(startDate);
    const date2: any = new Date(endDate);

    const differenceMs = Math.abs(date2 - date1);

    const differenceMonths = differenceMs / (1000 * 60 * 60 * 24 * 30);

    if (differenceMonths > 1) {
      notify.error(
        "We’re not able to search the audit log beyond a 30 day window. Please adjust your Start and End Dates and try again. ",
      );
    } else {
      auditLogsMutation({
        fromDate: startDate,
        toDate: endDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const tableData: any = auditLogs || [];

  return (
    <MarginWrapper>
      <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
        {!isYearly && (
          <Stack
            direction="row"
            mb={2}
            spacing={2}
            sx={{ width: dateError ? "30%" : "26%" }}
          >
            <DatePickerField
              label="Start Date" // Make This From Month
              openTo="month" // replace with month
              minDate={minDate}
              maxDate={maxDate}
              views={["year", "month", "day"]}
              format={getDateFormatset()}
              disableFuture
              onChange={() => {}}
              onAccept={handleFromDateChange}
              value={dayjs(startDate)}
              clearDate={clearDate}
              emission
            />
            <DatePickerField
              label="End Date"
              views={["year", "month", "day"]}
              openTo="year"
              minDate={minDate}
              maxDate={maxDate}
              format={getDateFormatset()}
              disableFuture
              onChange={() => {}}
              onAccept={handleToDateChange}
              value={dayjs(endDate)}
              clearDate={clearDate}
              emission
            />
            {dateError && (
              <Box sx={{ color: COLORS.Fuzzy_Brown, fontSize: "0.75rem" }}>
                Invalid date selection. Please choose a date after the From
                Date.
              </Box>
            )}
          </Stack>
        )}
        <Button
          sx={{
            width: "120px",
            paddingTop: "10px",
            border: "1px solid #000",
            marginTop: "27px",
          }}
          onClick={handleClearDates}
        >
          {/* Clear Filters */}
          Clear Dates
        </Button>
      </Box>
      {isLoading && <Loading />}
      <div>
        {isLoading ? (
          <Loading />
        ) : (
          <MaterialReactTable
            columns={AUDIT_LOGS_COLUMNS_MAIN as any}
            data={tableData}
            enableDensityToggle={false}
            initialState={{ density: "compact" }}
            enablePagination={false}
            muiTableContainerProps={{
              sx: {
                "& thead tr:first-child th:first-child": {
                  paddingLeft: "20px",
                },
                "& tbody tr td:first-child": {
                  paddingLeft: "20px",
                },
                height: "calc(100vh - 255px)",
                border: "1px solid #D9D9D9",
                borderRadius: "10px",
                minWidth: "100%",
                width: "100%",
                maxWidth: "100%",
                "@media (min-width: 600px)": {
                  width: "100%",
                },
                "@media (min-width: 960px)": {
                  width: "1100px",
                },
                "@media (min-width: 1280px)": {
                  width: "1100px",
                },
                "@media (min-width: 1440px)": {
                  width: "1100px",
                },
                "@media (min-width: 1680px)": {
                  width: "1300px",
                },
                "@media (min-width: 2400px)": {
                  width: "100%",
                },
              },
            }}
            muiTablePaperProps={{
              sx: {
                borderRadius: "10px",
                boxShadow: "none",
              },
            }}
            muiTopToolbarProps={{
              sx: {
                display: "none",
              },
            }}
            muiBottomToolbarProps={{
              sx: {
                display: "none",
              },
            }}
            muiTableHeadCellProps={{
              sx: {
                lineHeight: "30px",
                "& .MuiBadge-root": {
                  display: "none",
                },
              },
            }}
            muiTableHeadRowProps={{
              sx: {
                "& > th": {
                  paddingTop: 1,
                  paddingBottom: 1,
                },
                height: "45px",
                backgroundColor: "#F8FAFC",
              },
            }}
            muiTableBodyRowProps={{
              sx: {
                height: "45px",
                backgroundColor: "white",
              },
            }}
          />
        )}
      </div>
    </MarginWrapper>
  );
};

export default AuditLogsPage;
